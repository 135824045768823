<template>
	<div :class="{ 'pb-16': fixed }">
		<header
			class="flex items-center justify-center text-center h-16 px-4 bg-white viewport-max-width"
			:class="{ 'fixed z-100 top-0 right-0 left-0': fixed }"
		>
			<div class="basis-17 flex justify-start">
				<!-- Кнопка "Назад" -->
				<button
					v-if="showButtonBack"
					data-testid="header-btn-back"
					class="flex-shrink-0 inline-flex items-center justify-center w-6 h-6"
					@click="$emit('back')"
				>
					<img
						src="@/assets/icons/arrow-left.svg"
						alt=""
						width="24"
						height="24"
					/>
				</button>
			</div>

			<div class="absolute left-0 pl-4">
				<!-- @slot Идет перед заголовком -->
				<slot name="pre"></slot>
			</div>

			<!-- Заголовок -->
			<strong
				data-testid="header-title"
				class="block w-full text-lg leading-none font-black font-sirius line-clamp-2"
			>
				{{ title }}
			</strong>

			<div class="basis-17 flex items-center justify-end">
				<UiBalance
					v-if="balanceAmount && isBalanceVisible"
					:amount="balanceAmount"
				/>

				<!-- Дополнительный контент -->
				<Component
					:is="node"
					v-for="(node, i) in headerVNodes"
					:key="i"
					class="vnodes"
				></Component>
			</div>
		</header>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UiBalance from '@/components/ui/UiBalance.vue';
import { UserService } from '@/services/user';
import { useHeader } from '@/utils/composables';

interface Props {
	title?: string;
	fixed?: boolean;
	showButtonBack?: boolean;
}

withDefaults(defineProps<Props>(), {
	title: 'Рестораны',
	fixed: false,
	showButtonBack: true
});
defineEmits<{ (e: 'back'): void }>();

const { headerVNodes, isBalanceVisible } = useHeader();
const balanceAmount = computed(() => UserService.state.privateReward.balance);
</script>

<style>
.vnodes:not(:first-child) {
	margin-left: 0.5rem;
}
</style>
