import SearchLayout from '@/layouts/SearchLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import {
	auth,
	clearTempTerminals,
	geo,
	billPulling,
	deliveryRedirect,
	deliverySwitch,
	deliveryInit,
	checkBrands,
	serviceSync,
	removeDoubleSlash
} from './middleware';
import { ADDRESS_MAP_URL, ADDRESS_FORM_URL } from './constants';

export default [
	{
		path: '/',
		name: 'Home',
		meta: { layout: SearchLayout, isNavbarActive: true },
		component: () => import('../views/Home'),
		beforeEnter: [
			checkBrands,
			clearTempTerminals,
			geo,
			serviceSync,
			deliverySwitch
		]
	},
	{
		path: '/brands',
		name: 'Brands',
		meta: {
			layout: SearchLayout,
			isNavbarActive: true,
			isBalanceVisible: true
		},
		component: () => import('../views/Brands'),
		beforeEnter: [clearTempTerminals, geo, serviceSync, deliverySwitch]
	},
	{
		path: '/brands/booking',
		name: 'BrandsBooking',
		meta: { isNavbarActive: true },
		component: () => import('../views/BrandsBooking'),
		beforeEnter: [geo]
	},
	{
		path: '/filials',
		name: 'Filials',
		meta: { layout: SearchLayout, isNavbarActive: true },
		component: () => import('../views/Filials'),
		beforeEnter: [clearTempTerminals, geo, serviceSync, deliveryInit]
	},
	{
		path: '/filials/:filial_id',
		name: 'Menu',
		component: () => import('../views/Menu'),
		meta: { isBalanceVisible: true },
		beforeEnter: [billPulling]
	},
	{
		path: '/filials/:filial_id/bill',
		name: 'Bill',
		meta: { isBalanceVisible: true },
		component: () => import('../views/Bill/Bill.vue')
	},
	{
		path: '/bills/preorder/:preorder_id',
		name: 'OrderInformationBill',
		meta: { isBalanceVisible: true },
		component: () => {
			return import('../views/OrderInformation/OrderInformationBill.vue');
		},
		beforeEnter: [auth]
	},
	{
		path: '/filials/:filial_id/search',
		name: 'MenuSearch',
		meta: { layout: SearchLayout },
		component: () => import('../views/MenuSearch.vue')
	},
	{
		path: '/cart',
		name: 'Cart',
		meta: { isBalanceVisible: true },
		component: () => import('../views/Cart')
	},
	{
		path: '/filials/:filial_id/barcode-scanner/',
		name: 'BarcodeScanner',
		meta: { layout: EmptyLayout },
		component: () => import('../views/BarcodeScanner')
	},
	{
		path: '/filials/:filial_id/details',
		name: 'FilialDetails',
		component: () => import('../views/FilialDetails.vue'),
		beforeEnter: [clearTempTerminals, geo]
	},
	{
		path: '/map',
		name: 'Map',
		meta: { layout: SearchLayout },
		component: () => import('../views/Map.vue'),
		beforeEnter: [clearTempTerminals, geo, serviceSync]
	},
	{
		path: '/auth',
		name: 'Auth',
		meta: { isNavbarActive: true },
		component: () => import('../views/Auth.vue'),
		beforeEnter: [clearTempTerminals]
	},
	{
		path: '/search',
		name: 'Search',
		meta: { layout: SearchLayout },
		component: () => import('../views/Search'),
		beforeEnter: [
			clearTempTerminals,
			geo,
			serviceSync,
			deliverySwitch,
			deliveryInit
		]
	},
	{
		path: '/orders',
		name: 'OrderList',
		meta: { isNavbarActive: true, isBalanceVisible: true },
		component: () => import('../views/OrderList'),
		beforeEnter: [clearTempTerminals, auth, serviceSync, deliverySwitch]
	},
	{
		path: '/typ/:filial_id/order/:id',
		name: 'TYP',
		meta: { isNavbarActive: true },
		component: () => import('../views/TYP.vue'),
		beforeEnter: [clearTempTerminals, auth]
	},
	{
		path: '/orders/:id',
		name: 'OrderInformation',
		meta: { isNavbarActive: true },
		component: () => {
			return import('../views/OrderInformation/OrderInformationDefault.vue');
		},
		beforeEnter: [clearTempTerminals, auth]
	},
	{
		path: '/filials/:filial_id/order/:id',
		name: 'OrderInformationStatuses',
		component: () => import('../views/OrderInformationStatuses'),
		beforeEnter: [clearTempTerminals, auth]
	},
	{
		path: '/profile',
		name: 'Profile',
		meta: { isNavbarActive: true },
		component: () => import('../views/Profile.vue'),
		beforeEnter: [clearTempTerminals, auth, serviceSync]
	},
	{
		path: '/profile/form',
		name: 'ProfileForm',
		component: () => import('../views/ProfileForm.vue'),
		beforeEnter: [auth]
	},
	{
		path: '/faq',
		name: 'FAQ',
		component: () => import('../views/FAQ.vue')
	},
	{
		path: ADDRESS_MAP_URL,
		name: 'AddressMap',
		meta: {
			layout: EmptyLayout
		},
		component: () => import('../views/AddressMap.vue'),
		beforeEnter: [geo, deliveryRedirect]
	},
	{
		path: ADDRESS_FORM_URL,
		name: 'AddressForm',
		component: () => import('../views/AddressForm.vue'),
		beforeEnter: [deliveryRedirect]
	},
	{
		path: '/filials/:filial_id/barcode-manual',
		name: 'BarcodeManual',
		component: () => import('../views/BarcodeManual.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../views/NotFound.vue'),
		beforeEnter: [clearTempTerminals, removeDoubleSlash]
	}
];
