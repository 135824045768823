import { reactive, readonly } from 'vue';
import {
	getBalance,
	getUser,
	getUserProfile,
	setProfileLanguage,
	updateUser,
	deleteAvatar,
	postAvatar,
	getPrivateRewardBalance
} from '@/services/api';
import { PrivateRewardBalancePayload } from '@/types/interfaces/balance';
import { IUserPayload } from '@/types/interfaces/user';

interface State {
	privateReward: {
		balance?: number;
		available_balance: number;
		cashback_amount: number;
		cashback_used_balance: number;
	};
}

const state = reactive<State>({
	privateReward: {
		balance: undefined,
		available_balance: 0,
		cashback_amount: 0,
		cashback_used_balance: 0
	}
});

/**
 * Получение данных о пользователе
 */
const fetchUser = () => getUser();

/**
 * Получение профиля пользователя (содержит последний выбранный язык)
 */
const fetchProfile = () => getUserProfile();

/**
 * Получение баланса пользователя
 */
const fetchChocoBalance = (): Promise<number> => {
	return getBalance()
		.then(response => response.data.bonus.KZT + response.data.money.KZT)
		.catch(() => 0);
};

const fetchPrivateReward = (payload: PrivateRewardBalancePayload) => {
	return getPrivateRewardBalance(payload);
};

const fetchBalance = async (
	payload?: PrivateRewardBalancePayload,
	force = false
) => {
	if (state.privateReward.balance && !force) {
		return state.privateReward;
	}

	const { terminal_id, is_chocobalance_hidden } = payload || {};

	if (!terminal_id) {
		if (!is_chocobalance_hidden) {
			state.privateReward.balance = await fetchChocoBalance();
		}

		return state.privateReward;
	}

	try {
		const data = await fetchPrivateReward(payload || {});
		state.privateReward = data.attributes;
	} catch {
		if (!is_chocobalance_hidden) {
			state.privateReward.balance = await fetchChocoBalance();
		}
	}

	return state.privateReward;
};

/**
 * Обновление языка в профиле пользователя
 */
const updateProfileLanguage = (language: string) => {
	return setProfileLanguage(language);
};

/**
 * Обновление данных о пользователе
 */
const setUserProfile = (payload: IUserPayload) => updateUser(payload);

const UserService = {
	state: readonly(state),
	fetchUser,
	fetchProfile,
	fetchBalance,
	updateProfileLanguage,
	setUserProfile,
	deleteAvatar,
	uploadAvatar: postAvatar
};

export { UserService };
